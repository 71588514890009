class Viewer {
    constructor() {
        this.frame = document.querySelector('#viewer');
        this.triggers = document.querySelectorAll('.product-config, .config-toggle');
        this.listConfig = document.querySelector('#config__menu');
        this.saveConfig = document.querySelector('.config__save_btn')
        this.waiter = document.querySelector('.config__waiter');
        this.storageCat = '';       // string: category id
        this.storageMenu = '';
        this.newFullConfigs = null;     // string: id || anchor id
        this.scrollPositionWrapper = 0; // int: retrieve the scroll wrapper-menu position
        this.scrollPositionMenu = 0; // int: retrieve the scroll menu position
        this.allElements = {};
        this.configs = null;
        this.storage = '';
        this.isInit = false;
        this.choixDuModule = {
            id: 'cdm',
            name: 'Choix du module',
            elements: [
                {id: 'cdm1', type:'cdm', name: 'canapé 3 places 1 relaxation électrique droite', ref: '1969840', image: 'https://innersense.imgix.net/uploads/photo/file/977471/original/1969840_1589.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARL2HAVTN2BBI27NB%2F20210427%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210427T085126Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=979e78e414b1b7af394c5cedfeb6eddfdfc8bed1d1bcadaa98c55236191188f1&cs=srgb&q=75&fit=max&w=2000&h=2000&fm=pjpg'},
                {id: 'cdm2', type:'cdm', name: 'canapé 3 places 1 RE gauche', ref: '1969772', image: 'https://d32yxp5k2tyinj.cloudfront.net/uploads/photo/file/977586/thumb/shrine20190902-23356-1ynp1ri20190902-23356-1qi0ri9.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARL2HAVTN2BBI27NB%2F20210427%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210427T085342Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=06451bc3b4e00be9caa7ceecd1735cc4104817ffa928e4f164d1b1b241868097'},
                {id: 'cdm3', type:'cdm', name: 'canapé 3 places 1 relaxation manuelle à droite', ref: '2469052', image: 'https://d32yxp5k2tyinj.cloudfront.net/uploads/photo/file/977189/thumb/shrine20190902-2098-b1v4rr20190902-2098-1hcjrpr.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARL2HAVTN2BBI27NB%2F20210427%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210427T085452Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=b74608078a4b8aaed9f3119027c76e6b13e8e28ee617988944c1789f945a0b6b'},
                // {id: 'cdm4', type:'cdm', name: 'canapé 3 places 2 RE', ref: '1970112', image: 'https://d32yxp5k2tyinj.cloudfront.net/uploads/photo/file/977292/thumb/shrine20190902-2098-h02yfa20190902-2098-2yigpc.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARL2HAVTN2BBI27NB%2F20210427%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210427T085552Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=d212efc5153211f14e188d76681f493a6dcaddf399cc8c55fc9a097eb57801a5'}
            ]
        }
    }

    init() {
        Array.from(this.triggers).forEach((trigger) => {
            trigger.addEventListener('click', () => {
                this.initFrame(null, trigger);
            });
        });
    }

    initFrame(config, trigger) {
        if (!this.isInit) {
            this.waiter.classList.add('hidden');
            this.isInit = true;
            let fullConfigs; // array: all configurations
            // let ulConfigs = document.getElementById('configs');
            let viewerConfig = {};
            if(config !== null) {
                $('html, body').animate({ scrollTop: $('#viewer').offset().top - 100 }, 333);
                viewerConfig = {
                    type: 'init',
                    hideTuto: true,
                    hideButtonFullscreen: true,
                    hideButtonReset: true,
                    hideButtonScreenshot: true,
                    activateIdleRotation: true, // activation auto rotation
                    configurationCode: config.code,
                    backgroundShade: {
                        shadeTop: {
                            r: 255,
                            g: 255,
                            b: 255
                        },
                        shadeDown: {
                            r: 255,
                            g: 255,
                            b: 255
                        }
                    },
                    stringCSSDefault: '.button-list-down{width:0;}'
                }
            } else {
                if (this.frame.getAttribute('data-config')) {
                    viewerConfig = {
                        type: 'init',
                        hideTuto: true,
                        hideButtonFullscreen: true,
                        hideButtonReset: true,
                        hideButtonScreenshot: true,
                        activateIdleRotation: true, // activation auto rotation
                        configurationCode: this.frame.getAttribute('data-config'),
                        backgroundShade: {
                            shadeTop: {
                                r: 255,
                                g: 255,
                                b: 255
                            },
                            shadeDown: {
                                r: 255,
                                g: 255,
                                b: 255
                            }
                        },
                        stringCSSDefault: '.button-list-down{width:0;}'
                    }
                } else {
                    viewerConfig = {
                        type: 'init',
                        hideTuto: true,
                        hideButtonFullscreen: true,
                        hideButtonReset: true,
                        hideButtonScreenshot: true,
                        activateIdleRotation: true, // activation auto rotation
                        backgroundShade: {
                            shadeTop: {
                                r: 255,
                                g: 255,
                                b: 255
                            },
                            shadeDown: {
                                r: 255,
                                g: 255,
                                b: 255
                            }
                        },
                        stringCSSDefault: '.button-list-down{width:0;}'
                    }
                }
            }
            this.frame.contentWindow.postMessage(viewerConfig, '*');

            if(typeof trigger !== 'undefined') {
                if(trigger.getAttribute('data-config')) {
                    this.frame.contentWindow.postMessage({
                        type:'loadModel',
                        ref: trigger.getAttribute('data-ref'),
                        // Add a saved configuration if necessary
                        config_code: trigger.getAttribute('data-config')
                    }, '*');
                }
            }

            window.addEventListener('message', event => {
                let action = event.data;
                switch (action.type) {
                    // case 'furnitureLoaded':
                    //     this.viewer.contentWindow.postMessage({
                    //     type: 'getName'
                    //     }, '*');
                    // break;

                    // case 'furnitureName':
                    //     let getName = document.getElementById("get-name");
                    //     getName.innerHTML = action.name.toUpperCase();
                    //     let title = document.querySelector('.title');
                    //     title.style.visibility = 'visible';
                    //     break;

                    case 'APIFullConfig':
                        if(this.listConfig) {
                        // DELETE THE OLD MENU
                            this.listConfig.innerHTML = '';
                            this.saveConfig.classList.remove('hidden');
                            fullConfigs = JSON.parse(action.fullconfigObject);

                            // CREATE THE MENU, LOAD THE ELEMENT/MODULE (cf. functions below)
                            fullConfigs = fullConfigs.filter(config => {return config.type !== 'theme'});

                            const shadesConfigs = fullConfigs.filter(config =>  { return config.type === 'color'})
                            const accessories = fullConfigs.filter(config => {return config.type === 'model'});
                            let accessoriesConfigs = {};
                            if(accessories.length > 0) {
                                accessoriesConfigs = {id: 20, type: 'acc', name: 'Options', elements: accessories};
                            }


                            // reorganize the menus
                            this.newFullConfigs = [];
                            // this.newFullConfigs.push(this.choixDuModule);
                            // this.newFullConfigs.push(shadesConfigs);
                            shadesConfigs.map(configs => {
                                this.newFullConfigs.push(configs)
                              })
                            if(Object.keys(accessoriesConfigs).length > 0){
                                this.newFullConfigs.push(accessoriesConfigs);
                            }

                            this.newFullConfigs.forEach(configs => {
                                console.log(configs);
                                this.createMenu(configs);
                            });
                        }

                        /*
                        CLICK ON THE + TO ADD/DELETE A MODULE
                        CLICK ON THE MODULE TO SELECT AND MODIFY IT */

                        break;

                    default:
                        break;
                };
            });
        }
    }

    loadConfig(element) {
        this.frame.contentWindow.postMessage({
            type: 'selectConfiguration',
            parameters: JSON.stringify(element)
        }, '*');
    }

    loadModule(ref) {
        this.frame.contentWindow.postMessage({
            type: 'loadModel',
            ref: ref,
        }, '*');
    }

    createConfig(element, imgWrapper) {
        const imgContent = document.createElement('div');
        const textImg = document.createElement('div');
        const img = document.createElement('img');
        textImg.textContent = element.name;
        img.src = element.image;
        img.className = 'shade';
        if(!imgWrapper.classList.contains('last-depth')){
            imgWrapper.classList.add('last-depth');
        }
        imgContent.className = 'img-content';
        textImg.className = 'text-img';
        imgContent.appendChild(img);
        imgContent.appendChild(textImg);
        imgWrapper.appendChild(imgContent);

        if(element.is_selected === true) {
        img.classList.add('active');
        const textSelected = document.createElement('div');
        textSelected.className = 'text-selected';
        textSelected.textContent = element.name;
        const container = imgContent.closest('.container-content');
        container.parentNode.firstChild.appendChild(textSelected);
        }
        /* On click, load the element in the 3D
        and fill the storage to keep the menu open */
        imgContent.addEventListener('click', e => {
            e.preventDefault();
            const submenuScrolled = imgContent.closest('.last-depth');
            this.scrollPositionMenu = submenuScrolled.scrollTop;
            this.scrollPositionWrapper = document.getElementById('config__menu_wrapper').scrollTop;
            this.storageMenu = imgContent.closest('.container-content').getAttribute('id');

            if(element.type === 'cdm'){
                this.loadModule(element.ref);
            } else {
                this.loadConfig(element);;
            }
        });
    }

    checkChildren(element, ulElements, confID) {
        if (element.shades.length > 0) {
            this.allElements[confID] = element.shades;
            element.shades.forEach(shade => this.createConfig(shade, ulElements, confID))
        } else {
            element.children.forEach(child => {
                this.checkChildren(child, ulElements);
            })
        }
    }

    createSubmenu(configs, containerContent) {
        const separator = document.createElement('hr');
        const subContainerWrapper = document.createElement('div');
        separator.className = 'horizontal';
        subContainerWrapper.className = 'sub-container-wrapper';
        containerContent.appendChild(separator);
        containerContent.appendChild(subContainerWrapper);

        // check if categories
        if (configs.has_category === true){
            // check if there are really categories
            let counter = 0;
            configs.elements.map(element => {
                if(element.hasOwnProperty('children') === true){
                    counter++;
                }
            });
            if(counter > 0){
                    const categoryContainer = document.createElement('div');
                    // for each category
                    configs.elements.forEach(element => {
                        const categoryWrapper = document.createElement('div');
                        const category = document.createElement('div');
                        categoryContainer.classList.add('category-container');
                        categoryWrapper.className = 'category-wrapper';
                        category.className = 'category';
                        category.textContent = element.name;
                        categoryContainer.appendChild(categoryWrapper);
                        categoryWrapper.appendChild(category);
                        subContainerWrapper.appendChild(categoryContainer);
                        this.createCatContainer(element, categoryContainer, categoryWrapper, subContainerWrapper);
                    })
            } else {
                configs.elements.forEach(element => {
                    this.reateConfig(element, subContainerWrapper);
                });
            }
            } else {
                configs.elements.forEach(element => {
                    this.createConfig(element, subContainerWrapper);
                });
            }
    }

    checkChildrenShades(element, subContainerContent, imgWrapper) {
        // categories
        if (element.shades !== undefined && element.shades.length > 0) {
            subContainerContent.classList.add('children');
            // if shades is full, display the images
            element.shades.forEach(shade => {
                this.createConfig(shade, imgWrapper);
            });

        // subcategories
        } else {
            const categoryContainer = document.createElement('div');
            // if children is full, check again
            element.children.forEach(child => {
                const categoryWrapper = document.createElement('div');
                const category = document.createElement('div');
                categoryContainer.appendChild(categoryWrapper);
                categoryWrapper.appendChild(category);
                imgWrapper.appendChild(categoryContainer);
                category.textContent = child.name;
                categoryContainer.classList.add('category-container');
                category.className = 'category';
                categoryWrapper.classList.add('category-wrapper');
                this.createCatContainer(child, categoryContainer, categoryWrapper, imgWrapper);

                // keep the submenu img container open
                if (this.storageCat !== '' && this.storageCat === child.id) {

                    const categories = document.querySelectorAll('.category-container');
                    Array.from(categories).forEach(category => category.classList.add('hide'));
                    categoryContainer.classList.add('hide');
                    subContainerContent.classList.remove('hide');
                    imgWrapper.classList.remove('hide');
                }
            });
        }
    }

    displayPopup(configs) {
        const popupCloseButton = document.querySelector('.popup-close-button');
        const popupContainer = document.querySelector('.popup-container');
        let popupTitle = document.getElementById('popup-title');
        let ulElements = document.getElementById('popup-modules');
        ulElements.innerHTML = '';
        popupContainer.style.visibility = 'visible';
        popupTitle.innerHTML = configs.name;
        this.createSubmenu(configs, ulElements);

        // Close popup
        popupContainer.addEventListener('click', () => {
            popupContainer.style.visibility = 'hidden';
        });
        popupCloseButton.addEventListener('click', () => {
            popupContainer.style.visibility = 'hidden';
        });
    }

    createCatContainer(element, categoryContainer, categoryWrapper, subContainerWrapper) {
        const subContainerContent = document.createElement('div');
        const subContainerHeader = document.createElement('div');
        const currentCategory = document.createElement('div');
        const imgWrapper = document.createElement('div');
        const categoryBack = document.createElement('i');
        subContainerHeader.className = 'sub-container-header';
        currentCategory.className = 'current-category';
        subContainerContent.className = 'sub-container-content';
        subContainerContent.classList.add('hide');
        imgWrapper.className = 'img-wrapper';
        categoryBack.classList.add('fa', 'fa-angle-left');
        subContainerWrapper.appendChild(subContainerContent);
        subContainerContent.appendChild(subContainerHeader);
        subContainerHeader.appendChild(categoryBack);
        subContainerHeader.appendChild(currentCategory);
        subContainerContent.appendChild(imgWrapper);
        currentCategory.textContent = element.name.toUpperCase();

        // SHADES CATEGORIES
        if(element.shades){
            this.checkChildrenShades(element, subContainerContent, imgWrapper);
        }

        // on click on one category, create the submenu img container
        categoryWrapper.addEventListener('click', e => {
            e.preventDefault();
            categoryContainer.classList.add('hide');
            subContainerContent.classList.remove('hide');
           Array.from(imgWrapper.children).map(child => {
            if(child.classList.contains('category-container')){
              child.classList.remove('hide');
            }
           })
            this.storageCat = element.id;
        })

        // on click on back button, display the categories
        subContainerHeader.addEventListener('click', () =>{
            subContainerContent.classList.add('hide');
            categoryContainer.classList.remove('hide');
            this.storageCat = '';
        })

        // keep the submenu img container open
        if (this.storageCat !== '' && this.storageCat === element.id) {
            categoryContainer.classList.add('hide');
            subContainerContent.classList.remove('hide');
            imgWrapper.classList.remove('hide');
            Array.from(imgWrapper.children).map(child => {
                if(child.classList.contains('category-container')){
                    child.classList.remove('hide');
                }
            });
        }
    }

    createSubmenuAcc(configs, containerContent) {
        const subContainerWrapper = document.createElement('div')
        subContainerWrapper.className = 'sub-container-wrapper'
        containerContent.appendChild(subContainerWrapper);
        configs.elements.forEach(element => {
            this.createSubmenu(element, subContainerWrapper);
        });
    }

    createMenu(configs) {
        const menu = this.listConfig;
        const container = document.createElement('div');
        const containerHeader = document.createElement('div');
        const containerContent = document.createElement('div');
        const separator = document.createElement('hr');
        const menuLink = document.createElement('a');
        container.className = 'item-custom';
        containerHeader.className = 'container-header';
        containerContent.className = 'container-content';
        containerContent.classList.add('tab-content', 'hide');
        container.appendChild(containerHeader);
        container.appendChild(containerContent);
        containerHeader.appendChild(menuLink);
        menu.appendChild(container);
        // const id = configs.instanceId
        const id = this.newFullConfigs.indexOf(configs) + configs.id;
        menuLink.href = '#' + id;
        containerContent.id = id;
        menuLink.textContent = configs.name;
        if(configs.type === 'acc'){
            this.createSubmenuAcc(configs, containerContent);
        } else {
            this.createSubmenu(configs, containerContent);
        }

        // Use the storage to keep the (new) menu open
        if (this.storageMenu !== '' && (this.storageMenu === containerContent.id)) {
        // if(this.storageMenu !== '' && this.storageMenu === configs.instanceId) {
            containerContent.classList.remove('hide');
        }
        menuLink.addEventListener('click', e => {
            e.preventDefault();
            if (containerContent.classList.contains('hide')) {
                const contentArray = document.getElementsByClassName('tab-content');
                Array.from(contentArray).filter(ul => {return ('#' + ul.id !== e.currentTarget.getAttribute('href'))})
                .map(el => el.classList.add('hide'));
                containerContent.classList.remove('hide');
            } else {
                containerContent.classList.add('hide');
            }
        });

        if(containerContent.id === this.storageMenu){
        // retrieve the scroll position
            const retrieveScrollPosition = element => {
                Array.from(element.children).map(el => {
                    if(el.classList.contains('last-depth')){
                        el.scrollTop = this.scrollPositionMenu;
                    } else {
                        return retrieveScrollPosition(el);
                    }
                });
            }
            retrieveScrollPosition(containerContent);
        }
    }
}

export default Viewer;
