import Splide from "@splidejs/splide";

class Splider {
    constructor() {
        this.elem = document.querySelector(".splide--crossSelling");
        if (this.elem) this.init();
    }

    init() {
        this.splide = new Splide(this.elem, {
            arrows: true,
            breakpoints: {
                940: {
                    perPage: 2,
                },
                576: {
                    perPage: 1,
                },
            },
            gap: "2rem",
            pagination: false,
            perPage: 3,
            perMove: 1,
            type: "loop",
        }).mount();
    }
}

export default Splider;
