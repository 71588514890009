
import axios from 'axios';

let config = {
    baseURL: '',
    timeout: 10000,
};

const get = (host, url) => {
    config.baseURL = host;
    axios.get(url, config)
        .then(response => response.data)
        .catch(error => Promise.reject(error));
};

const del = (host, url) => {
    config.baseURL = host;
    return axios.delete(url, config)
        .then(response => response.data)
        .catch(error => Promise.reject(error));
};

const post = (host, url, params, options) => {
    config = { ...config, ...options };
    config.baseURL = host;
    return axios.post(url, params, config)
        .then(response => response)
        .catch(error => Promise.reject(error));
};

const put = (host, url, params) => {
    config.baseURL = host;
    return axios.put(url, params, config)
        .then(response => response.data)
        .catch(error => Promise.reject(error));
};

const patch = (host, url, params = false) => {
    config.baseURL = host;
    return axios.patch(url, params, config)
        .then(response => response.data)
        .catch(error => Promise.reject(error));
};

export default {
    get, post, put, del, patch,
};
