import axios from 'axios';
import * as FilePond from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import uploaderTranslate from './uploader.data';

class Uploader {
    constructor(node, form) {
        this.baseTarget = GLOBALS.FORM_ACTION; // eslint-disable-line
        this.filesUploads = [];
        this.form = form;
        this.unloadFlag = false;
        FilePond.registerPlugin(FilePondPluginFileValidateSize);
        FilePond.registerPlugin(FilePondPluginFileValidateType);
        const p = new Promise((resolve, reject) => { // eslint-disable-line
            Array.from(node.querySelectorAll('input[type="file"]')).forEach((upload) => {
                FilePond.setOptions({
                    server: {
                        process: (fieldName, file, metadata, load, error, progress, abort) => {
                            this.form.disableSubmit();
                            // fieldName is the name of the input field
                            // file is the actual file object to send
                            const formData = new FormData();
                            formData.append(fieldName, file);
                            formData.append('formID', document.querySelector('[name="formID"]').value);
                            this.processUpload(formData, load, error, progress);

                            // Should expose an abort method so the request can be canceled
                            return {
                                abort: () => {
                                    // This function is entered
                                    // if the user has tapped the cancel button
                                    // request.abort();

                                    // Let FilePond know the request has been cancelled
                                    abort();
                                },
                            };
                        },
                        revert: (source, load, error) => { // eslint-disable-line
                            this.form.disableSubmit();
                            const formData = new FormData();
                            formData.append('source', source);
                            this.abortUpload(formData, load, error);
                        },
                    },
                });
                const formats = upload.parentNode.getAttribute('data-accept').split(',');
                // formats = formats.map(i => `.${i}`);
                const defaultOptions = {
                    credits: false,
                    maxFileSize: `${upload.parentNode.getAttribute('data-maxFileSize')}MB`,
                    acceptedFileTypes: formats,
                    maxFiles: 5,
                    checkValidity: true,
                    instantUpload: false,
                    allowProcess: false,
                    allowRevert: false,
                    required: upload.hasAttribute('required'),
                    oninitfile: (file) => { // eslint-disable-line
                        // this.form.disableSubmit();
                    },
                };

                const pond = FilePond.create(upload, { ...defaultOptions, ...uploaderTranslate });
                this.filesUploads = pond;
            });
            resolve();
        });
        p.then(() => {
            this.form.init(this.filesUploads);
        });
    }

    processUpload(data, load, error, progress) { // eslint-disable-line
        const { CancelToken } = axios;
        const source = CancelToken.source();
        const target = `${this.baseTarget}?action=processUpload`;
        axios({
            method: 'post',
            url: target,
            data,
            // cancelToken: source.token,
            onUploadProgress: (e) => {
                // updating progress indicator
                progress(e.lengthComputable, e.loaded, e.total);
            },
            // passing the file id to FilePond
        }).then((response) => {
            if (!('error' in response.data)) {
                load(response.data.uniq);
            } else {
                error();
            }
        }).catch((thrown) => {
            if (axios.isCancel(thrown)) {
                console.log('Request canceled', thrown.message);
            } else {
                // handle error
                error();
            }
        });

        return {
            abort: () => {
                source.cancel('Operation canceled by the user.');
            },
        };
    }

    abortUpload(data, load, error) { // eslint-disable-line
        this.form.disableSubmit();
        const { CancelToken } = axios;
        const source = CancelToken.source();
        const target = `${this.baseTarget}?action=abortUpload`;
        axios({
            method: 'post',
            url: target,
            data,
            // passing the file id to FilePond
        }).then((response) => {
            if (!('error' in response.data)) {
                load(response.data.uniq);
            } else {
                error();
            }
        }).catch((thrown) => {
            if (axios.isCancel(thrown)) {
                console.log('Request canceled', thrown.message);
            } else {
                // handl e error
                error();
            }
        });

        return {
            abort: () => {
                source.cancel('Operation canceled by the user.');
            },
        };
    }
}

export default Uploader;
