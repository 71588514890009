// eslint-disable-next-line
const Storage = class {
    constructor(mainKey, persist) {
        if (persist) {
            this.$storage = window.localStorage;
        } else { this.$storage = window.sessionStorage; }
        this.$mainKey = mainKey;
        this.$fav = {};

        if (this.get(this.$mainKey) === null || !this.get(this.$mainKey) || this.get(this.$mainKey) === '') { this.$storage.setItem(this.$mainKey, JSON.stringify(this.$fav)); } else { this.$fav = this.get(this.$mainKey); }
    }

    set(data) {
        this.$storage.setItem(this.$mainKey, JSON.stringify(data));
    }

    remove(key) {
        this.$storage.removeItem(key);
    }

    removeVal(key) {
        const tmp = this.get(this.$mainKey);

        if (tmp) {
            delete tmp[key];
            this.$fav = tmp;
            this.set(tmp);
        }
    }

    get(key) {
        const res = JSON.parse(this.$storage.getItem(key));
        if (res !== null) { return res; }

        return false;
    }

    getVal(key) {
        const res = this.get(this.$mainKey);
        if (res !== null && res[key] !== null && res[key] !== undefined) { return res[key]; }

        return false;
    }

    update(key, data) {
        const tmp = this.get(this.$mainKey);
        if (tmp) {
            tmp[key] = data;
            this.$fav = tmp;
            this.set(tmp);
            return true;
        }

        return false;
    }

    getSize() {
        let key;
        let size = 0;
        // eslint-disable-next-line
        for (key in this.$fav)
            // eslint-disable-next-line
            if (this.$fav.hasOwnProperty(key))
                // eslint-disable-next-line
                size++;
        return size;
    }
};

export default Storage;
