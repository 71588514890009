import SlimSelect from 'slim-select'
import Form, { FormError } from 'coreJs/Form';
import datePicker from 'coreModules/datepicker';
import { French } from 'flatpickr/dist/l10n/fr';
// import Callback from './callback';
import Uploader from './uploader';

class App {
    constructor() {
        if(document.querySelector('.select__store_field')) {
            Array.from(document.querySelectorAll('.select__store_field')).forEach((storeselect) => {
                new SlimSelect({
                    select: `#${storeselect.getAttribute('id')}`,
                    searchText: 'Aucun magasin trouvé',
                    searchPlaceholder: 'Rechercher un magasin',
                });

                storeselect.parentElement.querySelector('.ss-search input').setAttribute('tabindex', storeselect.getAttribute('data-tab-index'));
            });
        }
        Array.from(document.querySelectorAll('.form__container')).forEach((node) => {
            try {
                Array.from(node.querySelectorAll('.datepicker')).forEach((datepicker) => {
                    const type = datepicker.getAttribute('name');
                    const datePickerConfig = this.datePickerConfig(type, datepicker)
                    datePicker(datepicker, datePickerConfig);
                });
                let async = true;
                if (node.querySelector('[type="file"]') !== null || node.classList.contains('form__container_payment')) {
                    async = false;
                }
                const form = new Form(node, {
                    async: true,
                    strictMode: false,
                    debug: true,
                });
                if (node.querySelector('input[type="file"]')) {
                    // async = false;
                    const uploader = new Uploader(node, form); // eslint-disable-line
                } else {
                    form.init();
                }

                const stateBlock = node.querySelector('.form__state');
                form.on('submit', () => {
                    node.querySelector('.submit img').style.display = 'block';
                })
                // form.on('submit.completed', (result) => {
                //     if (result.data.code === 'U1CREA' || result.data.code === 'U1RESPWD' || result.data.code === 'U1LOG' || result.data.code === 'F1PROCRE') {
                //         stateBlock.classList.remove('failed');
                //         stateBlock.classList.add('complete');
                //         if(typeof result.data.data !== 'undefined') {
                //             if(typeof result.data.data.iframe !== 'undefined') {
                //                 const wcb = new Callback();
                //                 wcb.addIframe(result.data.data.iframe);
                //             }
                //         } else {
                //             let redirect = '';
                //             if(typeof result.data.redirect === 'undefined') {
                //                 redirect = node.querySelector('[name="conf"]').value;
                //             } else {
                //                 redirect = result.data.redirect;
                //             }
                //             window.location.href = redirect; // eslint-disable-line
                //         }
                //     }
                //     else if (result.data.code === 'R0EMAEXI' || result.data.code === 'U0EXI') {
                //         stateBlock.classList.add('failed');
                //         stateBlock.classList.remove('complete');
                //         stateBlock.innerHTML = 'Vous êtes déjà inscrit avec cette adresse e-mail, veuillez utiliser le lien ci-dessous pour vous connecter à votre compte :';
                //     } else if (result.data.code === 'U1LPWD') {
                //         stateBlock.classList.remove('failed');
                //         stateBlock.classList.add('complete');
                //         stateBlock.innerHTML = 'Un email a été envoyé à l\'adresse que vous avez précisée avec la démarche à suivre pour réinitialiser votre mot de passe. Si vous n\'avez rien reçu, veuillez vérifier votre courrier indésirable/SPAM.';
                //     } else if (result.data.code === 'U0LOG') {
                //         stateBlock.classList.add('failed');
                //         stateBlock.classList.remove('complete');
                //         stateBlock.innerHTML = 'Email et/ou mot de passe incorrect(s). Si vous n\'avez pas encore de compte, vous pouvez en créer un en cliquant sur le lien "Créer un compte" ci-contre. Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser en cliquant sur le lien "J\'ai oublié mon mot de passe" ci-dessous.';
                //     } else if (result.data.code === 'F1PROUPD') {
                //         stateBlock.classList.add('failed');
                //         stateBlock.classList.remove('complete');
                //         stateBlock.innerHTML = 'Vos données ont bien été mises à jour.';
                //     } else {
                //         stateBlock.classList.add('failed');
                //         stateBlock.classList.remove('complete');
                //         stateBlock.innerHTML = 'Une erreur survenue. Veuillez réessayer.';
                //     }
                // });
                // form.on('submit.failed', (error) => {
                //     node.querySelector('.submit img').style.display = 'none';
                //     stateBlock.classList.add('failed');
                //     stateBlock.classList.remove('complete');
                //     stateBlock.innerHTML = 'Veuillez remplir tous les champs obligatoires du formulaire.';
                // });
            } catch (e) {
                if (!(e instanceof FormError)) {
                    throw e;
                }
                console.warn(e.message); // eslint-disable-line
            }
        });
    }

    datePickerConfig(type, datepicker) {
        return {
            maxDate: 'today',
            firstDayOfWeek: 1,
            dateFormat: 'd/m/Y',
            // disable: [
            //     function(date) { // eslint-disable-line
            //         // return true to disable
            //         return (date.getDay() === 0);
            //     },
            // ],
            locale: French,
            onClose: [
                function (selectedDates, dateStr, instance) { // eslint-disable-line
                    datepicker.blur();
                },
            ],
        }
    }
}

export default App;
