import qs from "qs";
import httpA from "coreJs/actions/httpActions";
import Storage from "./storage";
import Viewer from "./viewer3D";

class Product {
    constructor() {
        if (document.querySelector(".product")) {
            this.defaultPrice = document.querySelector(".product-price__price span");
            this.oldPrice = document.querySelector(".product-price__price-old span");
            this.ecopartPrice = document.querySelector(".product-price__ecopart span");
            this.iteration = document.querySelector(".product-financement__cost span");
            this.dropdownLabel = document.querySelector(".dropdown__label_places");
            this.saveConfig = document.querySelector(".config__save");
            this.saveConfigNonLogged = document.querySelector('.btn-open-modal[data-target="modal-config"]');
            this.saveConfigClose = document.querySelector('.btn-close-modal[data-target="modal-config"]');
            this.iframe = document.querySelector("#viewer");
            this.storage = new Storage("tmp_config", false);
            this.action = "save_config";
            this.feedback = document.querySelector(".config__feedback");
            this.delay = 10000;
            this.configData = {};
            this.interval = null;
            this.lock = {
                configSummary: true,
                configurationCode: true,
                save: false,
                delete: false,
            };
            this.initSingle();
            this.initConfig();
        } else if (document.querySelector(".account__config")) {
            this.saveConfig = document.querySelector(".config__save");
            this.iframe = document.querySelector("#viewer");
            this.loadConfig = document.querySelectorAll(".config__load");
            this.toggleDelete = document.querySelectorAll(".config__toggle-delete");
            this.deleteConfig = document.querySelectorAll(".config__delete");
            this.cancelDelete = document.querySelectorAll(".config__delete_cancel");
            this.storage = new Storage("tmp_config", false);
            this.action = "save_config";
            this.configData = {};
            this.interval = null;
            this.lock = {
                configSummary: true,
                configurationCode: true,
                save: false,
                delete: false,
            };
            this.initConfig();
        }

        if (!document.querySelector(".single-product")) return;

        this.popup = document.querySelector(".slider__popup");
        this.close = this.popup.querySelector(".close");
        this.slider = document.querySelector(".block-slider");
        this.img = null;

        this.initSliderPopup();
    }

    initSingle() {
        Array.from(document.querySelectorAll(".product__places-selector")).forEach((item) => {
            item.addEventListener("click", () => {
                const price = item.dataset;
                const { varid } = item.dataset;
                document.querySelector(".modal-financement-text").innerHTML =
                    PAYMENT[varid].text.popin_content;
                this.dropdownLabel.innerHTML = item.innerHTML;
                if (this.defaultPrice) {
                    this.defaultPrice.innerHTML = price.global;
                }

                if (price.old !== undefined) {
                    this.oldPrice.innerHTML = price.old;
                    this.oldPrice.parentElement.style.display = "block";
                } else {
                    this.oldPrice.innerHTML = "";
                    this.oldPrice.parentElement.style.display = "none";
                }

                if (this.ecopartPrice) {
                    this.ecopartPrice.innerHTML = price.ecopart;
                }

                if (this.iteration) {
                    this.iteration.innerHTML = price.iteration;
                }
            });
        });

        if (document.querySelector(".option__colors_more")) {
            const colorMore = document.querySelector(".option__colors_more");
            colorMore.addEventListener("click", () => {
                const outter = document.querySelector(".list-colors__outter");
                outter.classList.toggle("full");
                if (outter.classList.contains("full")) {
                    colorMore.innerHTML = "Voir - de couleurs";
                } else {
                    colorMore.innerHTML = "Voir + de couleurs";
                }
            });
        }
    }

    initConfig() {
        const storedConfig = this.storage.get("tmp_config");

        if (Object.keys(storedConfig).length !== 0 && storedConfig.constructor === Object) {
            if (GLOBALS.USER_LOGGED_IN) {
                // this.initFrameOnLoad(storedConfig);
                const view = new Viewer();
                view.initFrame(storedConfig);
                httpA
                    .post("", GLOBALS.FORM_ACTION, qs.stringify(storedConfig), { responseType: "text" }) // eslint-disable-line
                    .then((response) => {
                        this.lock.save = false;
                        this.saveConfig.classList.remove("lock");
                        this.storage.remove("tmp_config");
                        this.showFeedback(response.data);
                    })
                    .catch((e) => {
                        console.error(e); // eslint-disable-line
                    });
            } else {
                this.lock.save = false;
                // this.saveConfig.classList.remove('lock');
                this.storage.remove("tmp_config");
                this.showFeedback({
                    status: "wrong",
                    message: "Vous devez être connecté avant de pouvoir enregistrer une configuration.",
                });
            }
        } else {
            this.storage.remove("tmp_config");
        }

        if (this.saveConfig) {
            this.saveConfig.addEventListener("click", () => {
                if (!this.lock.save) {
                    this.saveConfig.classList.add("lock");
                    this.lock.save = true;
                    const frame = this.iframe;
                    const iframeCall = new Promise((resolve, reject) => {
                        try {
                            frame.contentWindow.postMessage(
                                {
                                    type: "getConfigurationSummary",
                                },
                                "*"
                            );
                            frame.contentWindow.postMessage(
                                {
                                    type: "getConfigurationCode",
                                },
                                "*"
                            );

                            this.interval = setInterval(() => {
                                if (!this.lock.configSummary && !this.lock.configurationCode) {
                                    this.lock.configurationCode = true;
                                    this.lock.configSummary = true;
                                    resolve();
                                }
                            }, 300);
                        } catch (e) {
                            reject(e);
                        }
                    });
                    iframeCall.then((v) => {
                        clearInterval(this.interval);
                        const datas = {
                            action: this.action,
                            summary: this.configData.summary,
                            code: this.configData.code,
                            product: document.querySelector('[name="product_id"]').value,
                        };

                        httpA
                            .post("", GLOBALS.FORM_ACTION, qs.stringify(datas), { responseType: "text" }) // eslint-disable-line
                            .then((response) => {
                                this.lock.save = false;
                                this.saveConfig.classList.remove("lock");
                                this.showFeedback(response.data);
                            })
                            .catch((e) => {
                                console.error(e); // eslint-disable-line
                            });
                    });
                }
            });
        }

        if (this.saveConfigClose) {
            this.saveConfigClose.addEventListener("click", () => {
                this.lock.save = false;
                this.saveConfigNonLogged.classList.remove("lock");
                this.storage.remove("tmp_config");
            });
        }

        if (this.saveConfigNonLogged) {
            this.saveConfigNonLogged.addEventListener("click", () => {
                if (!this.lock.save) {
                    this.saveConfigNonLogged.classList.add("lock");
                    this.lock.save = true;
                    const frame = this.iframe;
                    const iframeCall = new Promise((resolve, reject) => {
                        try {
                            frame.contentWindow.postMessage(
                                {
                                    type: "getConfigurationSummary",
                                },
                                "*"
                            );
                            frame.contentWindow.postMessage(
                                {
                                    type: "getConfigurationCode",
                                },
                                "*"
                            );

                            this.interval = setInterval(() => {
                                if (!this.lock.configSummary && !this.lock.configurationCode) {
                                    this.lock.configurationCode = true;
                                    this.lock.configSummary = true;
                                    resolve();
                                }
                            }, 300);
                        } catch (e) {
                            reject(e);
                        }
                    });
                    iframeCall.then((v) => {
                        clearInterval(this.interval);
                        const datas = {
                            action: this.action,
                            summary: this.configData.summary,
                            code: this.configData.code,
                            product: document.querySelector('[name="product_id"]').value,
                        };

                        this.storage.set(datas);
                    });
                }
            });
        }

        if (this.loadConfig) {
            Array.from(this.loadConfig).forEach((btn) => {
                btn.addEventListener("click", () => {
                    if (!btn.classList.contains("config-toggle")) {
                        Array.from(document.querySelectorAll(".block-accordeon__name")).forEach((load) => {
                            load.classList.remove("active");
                        });
                    }
                    btn.closest(".block-accordeon__block")
                        .querySelector(".block-accordeon__name")
                        .classList.add("active");
                    this.iframe.contentWindow.postMessage(
                        {
                            type: "loadModel",
                            ref: btn.getAttribute("data-ref"),
                            // Add a saved configuration if necessary
                            config_code: btn.getAttribute("data-config"),
                        },
                        "*"
                    );
                });
            });
        }

        if (this.iframe) {
            window.addEventListener("message", (event) => {
                const action = event.data;
                switch (action.type) {
                    case "configurationSummary":
                        this.configData.summary = action.configSummary;
                        this.lock.configSummary = false;
                        break;

                    case "configurationCode":
                        this.configData.code = action.configCode;
                        this.lock.configurationCode = false;
                        break;
                }
            });
        }

        if (this.toggleDelete) {
            Array.from(this.toggleDelete).forEach((toggle) => {
                toggle.addEventListener("click", () => {
                    toggle.parentElement.querySelector(".config__confirm_delete").classList.add("visible");
                });
            });
        }

        if (this.cancelDelete) {
            Array.from(this.cancelDelete).forEach((toggle) => {
                toggle.addEventListener("click", () => {
                    toggle.parentElement.classList.remove("visible");
                });
            });
        }

        if (this.deleteConfig) {
            Array.from(this.deleteConfig).forEach((btnDel) => {
                btnDel.addEventListener("click", () => {
                    if (!this.lock.delete) {
                        this.lock.delete = true;
                        const parent = btnDel.closest(".block-accordeon__block");
                        btnDel.classList.add("hidden");
                        parent.querySelector(".config__delete_cancel").classList.add("hidden");
                        parent.querySelector(".config__deleting").classList.remove("hidden");

                        const datas = {
                            action: "delete_config",
                            product: btnDel.getAttribute("data-product"),
                            key: btnDel.getAttribute("data-key"),
                        };
                        httpA
                            .post("", GLOBALS.FORM_ACTION, qs.stringify(datas), { responseType: "text" }) // eslint-disable-line
                            .then((response) => {
                                this.lock.delete = false;
                                if (
                                    typeof response.data.remove !== undefined &&
                                    response.data.remove === "parent"
                                ) {
                                    btnDel.closest(".block-accordeon__item").remove();
                                } else {
                                    btnDel.closest(".block-accordeon__block").remove();
                                }
                            })
                            .catch((e) => {
                                btnDel.classList.remove("hidden");
                                parent.querySelector(".config__toggle_delete").classList.remove("hidden");
                                parent.querySelector(".config__deleting").classList.add("hidden");
                                console.error(e); // eslint-disable-line
                            });
                    }
                });
            });
        }
    }

    showFeedback(data) {
        if (this.feedback) {
            this.feedback.innerHTML = data.message;
            this.feedback.classList.add(data.status);
            this.feedback.classList.remove("hidden");
            setTimeout(() => {
                this.feedback.classList.add("hidden");
                setTimeout(() => {
                    this.feedback.classList.remove(data.status);
                    this.feedback.innerHTML = "";
                }, 400);
            }, this.delay);
        }
    }

    initFrameOnLoad(config) {
        document.querySelector(".config__waiter").classList.add("hidden");
        $("html, body").animate({ scrollTop: $("#viewer").offset().top - 100 }, 333);
        const viewerConfig = {
            type: "init",
            hideTuto: true,
            hideButtonFullscreen: true,
            hideButtonReset: true,
            hideButtonScreenshot: true,
            activateIdleRotation: true, // activation auto rotation
            configurationCode: config.code,
            backgroundShade: {
                shadeTop: {
                    r: 255,
                    g: 255,
                    b: 255,
                },
                shadeDown: {
                    r: 255,
                    g: 255,
                    b: 255,
                },
            },
            stringCSSDefault: ".button-list-down{width:0;}",
        };
        this.iframe.contentWindow.postMessage(viewerConfig, "*");

        window.addEventListener("message", (event) => {
            const action = event.data;
            switch (action.type) {
                // case 'furnitureLoaded':
                //     this.viewer.contentWindow.postMessage({
                //     type: 'getName'
                //     }, '*');
                // break;

                // case 'furnitureName':
                //     let getName = document.getElementById("get-name");
                //     getName.innerHTML = action.name.toUpperCase();
                //     let title = document.querySelector('.title');
                //     title.style.visibility = 'visible';
                //     break;

                case "APIFullConfig":
                    if (this.listConfig) {
                        // DELETE THE OLD MENU
                        this.listConfig.innerHTML = "";
                        this.saveConfig.classList.remove("hidden");
                        fullConfigs = JSON.parse(action.fullconfigObject);

                        // CREATE THE MENU, LOAD THE ELEMENT/MODULE (cf. functions below)
                        fullConfigs.forEach((configs) => {
                            this.createMenu(configs);
                        });
                    }

                    /*
                    CLICK ON THE + TO ADD/DELETE A MODULE
                    CLICK ON THE MODULE TO SELECT AND MODIFY IT */

                    break;

                default:
                    break;
            }
        });
    }

    initSliderPopup() {
        this.slider.addEventListener("click", ({ target }) => {
            if (target.tagName !== "IMG") return;

            this.img = Product.buildImage(target.src);
            this.popup.append(this.img);
            this.popup.classList.add("active");
        });

        this.close.addEventListener("click", () => {
            this.closePopup();
        });

        window.addEventListener("keydown", ({ key }) => {
            if (key !== "Escape") return;
            this.closePopup();
        });
    }

    static buildImage(src) {
        const img = document.createElement("img");
        img.src = src;

        return img;
    }

    closePopup() {
        this.popup.classList.remove("active");
        if (this.img) this.img.remove();
    }
}

export default Product;
