class Home {
    constructor() {
        if(document.querySelector('.home')) {
            this.storeSelect = document.querySelector('.home__goto_store');

            this.storeSelect.addEventListener('change', () => {
                window.location.href = this.storeSelect.value;
            });
        }
    }
}

export default Home;
