import qs from 'qs';
import httpA from 'coreJs/actions/httpActions';
import Storage from './storage';
import isMobile from './isMobile';


class Geoloc {
    constructor() {
        this.storage = new Storage('geoloc', false);
        this.action = 'geoloc_store';
        this.elements = {
            triggerGeoloc: document.querySelector('.geoloc__trigger'),
            name: document.querySelector('.geoloc__store_name'),
            status: document.querySelector('.geoloc__store_status'),
        };

        this.currentTime = new Date();
        this.today = this.currentTime.getDay();

        this.init();
    }

    init() {
        if (isMobile()) {
            if(this.elements.triggerGeoloc) {
                this.elements.triggerGeoloc.setAttribute('href', this.elements.triggerGeoloc.getAttribute('data-store-locator'));
                this.elements.triggerGeoloc.classList.remove('geoloc__trigger');
            }
        } else {
            const storedGeoloc = this.storage.get('geoloc');
            if (Object.keys(storedGeoloc).length === 0 && storedGeoloc.constructor === Object) {
                if (this.elements.triggerGeoloc) {
                    this.elements.triggerGeoloc.addEventListener('click', (e) => {
                        if (document.querySelector('.geoloc__store').classList.contains('geoloc__trigger')) {
                            console.log(document.querySelector('.geoloc__store').classList);
                            e.preventDefault();
                            this.elements.status.innerHTML = 'En cours...';
                            this.initGeoloc();
                        }
                    });
                }
            } else {
                this.elements.triggerGeoloc.classList.remove('geoloc__trigger');
                this.display();
            }
        }
    }

    async initGeoloc() {
        navigator.geolocation.getCurrentPosition((position) => {
            const datas = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                action: this.action,
            };
            httpA.post('', GLOBALS.FORM_ACTION, qs.stringify(datas), { responseType: 'text' }) // eslint-disable-line
                .then((response) => {
                    const storeInfos = response.data;
                    this.storage.set(storeInfos);
                    this.display();
                }).catch((e) => {
                    console.error(e); // eslint-disable-line
                });
        }, () => {
            this.elements.triggerGeoloc.setAttribute('href', this.elements.triggerGeoloc.getAttribute('data-store-locator'));
            this.elements.triggerGeoloc.classList.remove('geoloc__trigger');
            this.elements.status.innerHTML = 'Voir les magasins';
        });
    }

    display() {
        const storedGeoloc = this.storage.get('geoloc');
        if (this.elements.name) {
            this.elements.name.innerHTML = storedGeoloc.store.store_data.city;
        }
        if (this.elements.triggerGeoloc) {
            this.elements.triggerGeoloc.setAttribute('href', storedGeoloc.store.store_data.link);
            this.elements.triggerGeoloc.classList.remove('geoloc__trigger');
        }
        if (this.elements.status) {
            const status = this.isStoreOpen(storedGeoloc.hours);
            let storeState = 'Fermé';
            if (status.status === 1 && !storedGeoloc.forceClose) {
                storeState = `Ouvert jusqu'à ${status.closing}`;
            }
            this.elements.status.innerHTML = storeState;
        }
    }

    getOpeningPeriods(hours) { // eslint-disable-line
        const tmp = [];
        Array.from(hours).forEach((val) => {
            const tmpDate = new Date();
            let hourminute = val;
            // prevent issue with hour before 10h because there is no leading 0
            if (hourminute < 1000) {
                hourminute = `0${val}`;
            }
            const str = hourminute.toString();
            const h = str.substring(0, 2);
            const m = str.substring(2);
            tmpDate.setHours(h);
            tmpDate.setMinutes(m);
            tmp.push(tmpDate);
        });
        return tmp;
    }

    isStoreOpen(hours) {
        if (this.today === 0) {
            return false;
        } else { // eslint-disable-line
            let storeStartTime = null;
            let storePauseTime = null;
            let storeRestartTime = null;
            let storeEndTime = null;
            // eslint-disable-next-line
            [storeStartTime, storePauseTime, storeRestartTime, storeEndTime] = this.getOpeningPeriods(hours[this.today]);

            if ((this.currentTime.getTime() >= storeStartTime.getTime()
                && this.currentTime.getTime() <= storePauseTime.getTime())
                || (typeof storeRestartTime !== 'undefined'
                && typeof storeEndTime !== 'undefined'
                && (this.currentTime.getTime() >= storeRestartTime.getTime()
                && this.currentTime.getTime() <= storeEndTime.getTime()))) {
                if (this.currentTime.getTime() < storePauseTime.getTime()) {
                    const h = this.addZero(storePauseTime.getHours());
                    const m = this.addZero(storePauseTime.getMinutes());
                    return { status: 1, closing: `${h}h${m}` };
                } else { // eslint-disable-line
                    const h = this.addZero(storeEndTime.getHours());
                    const m = this.addZero(storeEndTime.getMinutes());
                    return { status: 1, closing: `${h}h${m}` };
                }
            } else { // eslint-disable-line
                return { status: 0 };
            }
        }
    }

    addZero(i) { // eslint-disable-line
        let withleading = i;
        if (i < 10) {
            withleading = `0${i}`;
        }
        return withleading;
    }
}

export default Geoloc;
