export default {
    labelDecimalSeparator: 'auto',
    labelThousandsSeparator: 'auto',
    labelIdle: 'Déposez vos fichiers pour les téléverser ou sélectionnez des fichiers',
    labelInvalidField: 'Certains fichiers ne sont pas valides',
    labelFileWaitingForSize: 'Calcul de la taille du fichier...',
    labelFileSizeNotAvailable: 'Taille du fichier non disponible',
    labelFileLoading: 'Chargement',
    labelFileLoadError: 'Une erreur est survenue durant le chargement',
    labelFileProcessing: 'Téléversement',
    labelFileProcessingComplete: 'Téléversement réussi',
    labelFileProcessingAborted: 'Téléversement interrompu',
    labelFileProcessingError: 'Une erreur est survenue durant le téléversement',
    labelFileProcessingRevertError: 'Une erreur est survenue durant le téléversement',
    labelFileRemoveError: 'Une erreur est survenue durant la suppression',
    labelTapToCancel: 'Appuyez pour supprimer',
    labelTapToRetry: 'Appuyez pour recommencer',
    labelTapToUndo: 'Appuyez pour supprimer',
    labelButtonRemoveItem: 'Supprimer',
    labelButtonAbortItemLoad: 'Interrompre',
    labelButtonRetryItemLoad: 'Essayer à nouveau',
    labelButtonAbortItemProcessing: 'Annuler',
    labelButtonUndoItemProcessing: 'Annuler',
    labelButtonRetryItemProcessing: 'Essayer à nouveau',
    labelButtonProcessItem: 'Téléverser',
    labelFileTypeNotAllowed: 'Format non valide',
    fileValidateTypeLabelExpectedTypes: 'Format(s) autorisé(s) {allTypes}',
    labelMaxFileSizeExceeded: 'Le fichier est trop volumineux',
    labelMaxFileSize: 'Taille maximale pour chaque fichier : {filesize}',
    labelMaxTotalFileSizeExceeded: 'Limite totale dépassée',
    labelMaxTotalFileSize: 'Taille maximale pour tous les fichiers : {filesize}',
    fileValidateTypeLabelExpectedTypesMap: {
        'application/msword': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.oasis.opendocument.text': 'ODT',
        'application/pdf': 'PDF',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
        'application/vnd.oasis.opendocument.spreadsheet': 'ODS',
        'text/csv': 'CSV',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'application/vnd.oasis.opendocument.presentation': 'ODP',
        txt: 'TXT',
        'image/jpeg': 'JP(E)G',
        'image/png': 'PNG',
        'image/gif': 'GIF',
        '.key': 'Key',
        '.pages': 'Pages',
        '.numbers': 'Numbers',
    },
};
