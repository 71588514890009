import GLightbox from './vendors/glightbox';

class Lightbox {
    constructor() {
        if (document.querySelector('.glightbox-popin-img')) {
            const lightbox = GLightbox({
                selector: '.glightbox-popin-img',
                touchNavigation: true,
                autoplayVideos: true,
                zoomable: true,
                draggable: true,
            });
            lightbox.open();
        }

        if (document.querySelector('.glightbox-popin-video')) {
            const lightbox = GLightbox({
                selector: '.glightbox-popin-video',
                touchNavigation: true,
                loop: true,
                autoplayVideos: true,
                zoomable: true,
                draggable: true,
                plyr: {
                    config: {
                        ratio: '16:9', // or '4:3'
                        muted: false,
                        hideControls: true,
                        youtube: {
                            noCookie: true,
                            rel: 0,
                            showinfo: 0,
                            iv_load_policy: 3,
                        },
                        vimeo: {
                            byline: false,
                            portrait: false,
                            title: false,
                            speed: true,
                            transparent: false,
                        },
                    },
                },
            });
            lightbox.open();
        }
    }
}

export default Lightbox;
