import validator from "validator"; // eslint-disable-line
import httpA from "coreJs/actions/httpActions"; // eslint-disable-line
import objectAssignDeep from "object-assign-deep";
import zxcvbn from "@novembrecom/zxcvbn"; // eslint-disable-line
import passwordMessages from "coreData/passwordMessages.json"; // eslint-disable-line
import qs from "qs";
import Callback from "mainJs/callback";

document.addEventListener("DOMContentLoaded", () => {
    const togglePassVisib = document.getElementsByClassName("input__password_toggle");

    Array.prototype.forEach.call(togglePassVisib, (el) => {
        el.addEventListener("click", () => {
            const input = el.previousElementSibling;
            el.classList.toggle("text");
            input.type = input.type === "password" ? "text" : "password";
        });
    });
});

const showCondElement = ($field) => {
    const $condFields = document.querySelectorAll(`[data-condName="${$field.name}"]`);
    $condFields.forEach(($condField) => {
        let { value } = $field;
        if ($field.tagName === "select") {
            value = $field.querySelector("option:selected");
        }
        if (value === $condField.dataset.condvalue) {
            $condField.parentElement.parentElement.classList.remove("hide"); // Climb to input__wrap parent
            $condField.setAttribute("required", "required");
            $condField.removeAttribute("data-ignore");
        } else {
            $condField.parentElement.parentElement.classList.add("hide");
            $condField.removeAttribute("required");
            $condField.setAttribute("data-ignore", 1);
        }
    });
};

/*
// EXAMPLE
Array.from(document.querySelectorAll('form')).forEach((node) => {
    const form = new Form(node, {
        async: true,
        strictMode: false,
        debug: true,
    });

    try {
        form.init();
        form.on('submit', () => {
            console.info('SUBMIT');
        });
    } catch (e) {
        if (!(e instanceof FormError)) {
            throw e;
        }
        if (form.options.debug) {
            console.warn(e.message); // eslint-disable-line
        }
    }
});
*/

class FormError extends Error {
    constructor(...args) {
        super(args);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FormError);
        }
    }
}

class Form {
    constructor(node, customOptions = {}) {
        this.$form = node;
        this.options = objectAssignDeep.noMutate(Form.DEFAULT_CONFIG, customOptions);

        this.locale = "fr-FR";
        this.action = null;
        this.$submit = null;
        this.pwdMin = this.options.pwdMin ? this.options.pwdMin : 3;
        this.$fields = [];
        this.$passwords = [];
        this.$metter = null;
        this.errors = {};
        this.fileHasError = false;
        this.fileponds = false;
        this.data = {
            fields: {},
        };
        this.events = {
            [Form.EVENT_SUBMIT]: null,
            [Form.EVENT_SUBMIT_COMPLETED]: null,
            [Form.EVENT_SUBMIT_FAILED]: null,
        };

        if (typeof CUSTOM_MESSAGES === "object") {
            this.CUSTOM_MESSAGES = CUSTOM_MESSAGES; // eslint-disable-line
        } else {
            this.CUSTOM_MESSAGES = {};

            if (this.options.debug) {
                console.warn(`Missing CUSTOM_MESSAGES constant @ #${this.$form.id}`); // eslint-disable-line
            }
        }

        if (typeof MESSAGES === "object") {
            this.MESSAGES = MESSAGES; // eslint-disable-line
        } else {
            this.MESSAGES = {};

            if (this.options.debug) {
                console.warn(`Missing MESSAGES constant @ #${this.$form.id}`); // eslint-disable-line
            }
        }
    }

    setupFields() {
        this.$fields = Array.from(
            this.$form.querySelectorAll(
                'input:not([type="submit"]):not([name="action"]):not([name="locale"]):not([type="search"]), textarea, select'
            )
        );
    }

    init(fileponds = false) {
        if (!this.$form) {
            throw new FormError(`Form not found @ #${this.$form.id}`);
        }

        if (fileponds) {
            this.fileponds = fileponds;
        }

        // INIT FIELDS

        this.setupFields();
        this.$metter = document.getElementById("metter");

        const $locale = this.$form.querySelector("input[name=locale]");
        if ($locale) {
            if (!$locale.value) {
                throw new FormError(`Custom locale is set but invalid @ #${this.$form.id}`);
            }
            this.locale = $locale.value;
        }

        const $action = this.$form.querySelector("input[name=action]");
        if ($action && $action.value) {
            this.action = $action.value;
        } else {
            throw new FormError(`Action field missing @ #${this.$form.id}`);
        }

        this.$submit = this.$form.querySelector("[type=submit]");

        // INIT EVENT LISTENERS
        this.$fields.forEach(($field) => {
            const $parent = $field.parentNode;

            if ($field.type !== "hidden" || $field.type === "search") {
                if ($field.value === "") {
                    $parent.classList.remove("filled");
                } else {
                    $parent.classList.add("filled");
                }

                $field.addEventListener("focusin", () => {
                    if (!$parent.classList.contains("touched")) {
                        $parent.classList.add("touched");
                    }
                    $parent.classList.add("focused");
                });

                $field.addEventListener("focusout", () => {
                    $parent.classList.remove("focused");
                });

                if ($field.dataset.cond) {
                    showCondElement($field);
                    if ($field.tagName === "select") {
                        $field.addEventListener("change", () => {
                            showCondElement($field);
                        });
                    } else {
                        $field.addEventListener("input", () => {
                            showCondElement($field);
                        });
                    }
                }
            }

            const changeCallback = () => {
                if ($field.type !== "hidden") {
                    if ($field.value === "") {
                        $parent.classList.remove("filled");
                    } else {
                        $parent.classList.add("filled");
                    }
                }

                this.validate($field);
                // this.enableSubmit();
            };

            if ($field.name === "password") {
                $field.addEventListener("input", changeCallback);
            }

            $field.addEventListener("blur", changeCallback);
        });

        this.$form.addEventListener("submit", (e) => {
            e.preventDefault();
            this.disableSubmit();
            this.submit();
        });

        /*
         * DEFAULT SUBMIT BEHAVIOURS
         * You can overwrite submit / submit.completed / submit.failed
         */

        // When the request is succesfully completed
        this.on("submit.completed", (result) => {
            const $state = this.$form.querySelector(".form__state");
            $state.innerHTML = "";

            const $message = document.createElement("div");
            $message.classList.add("form__message", "complete");
            $message.textContent = this.getMessage(result.data.code);
            $state.appendChild($message);

            this.$form.classList.remove("failed");
            this.$form.classList.add("complete");

            this.disable();
            console.log(result);
            if (typeof result.data.data !== "undefined") {
                if (typeof result.data.data.iframe !== "undefined") {
                    const wcb = new Callback();
                    wcb.addIframe(result.data.data.iframe);
                }
            } else if (result.data.code === "U1LPWD") {
                document.querySelector(".input__wrap_submit img").remove();
                $message.innerHTML =
                    "Un email a été envoyé à l'adresse que vous avez précisée avec la démarche à suivre pour réinitialiser votre mot de passe. Si vous n'avez rien reçu, veuillez vérifier votre courrier indésirable/SPAM.";
            } else if (result.data.code === "U0LPWD") {
                document.querySelector(".input__wrap_submit img").remove();
                $message.innerHTML = "L'email ne correspond à aucun compte";
            } else if (result.data.code === "U0ALPWD") {
                document.querySelector(".input__wrap_submit img").remove();
                $message.innerHTML =
                    "Vous avez déjà effectué une demande de réinitialisation de mot de passe. Veuillez vérifier votre boîte mail ou votre courrier indésirable et suivre la démarche indiquée dans le mail que vous avez reçu.";
            } else {
                let redirect = "";
                if (typeof result.data.redirect === "undefined") {
                    redirect = this.$form.querySelector('[name="conf"]').value;
                } else {
                    redirect = result.data.redirect; //eslint-disable-line
                }
                if (redirect !== "") {
                    window.location.href = redirect; // eslint-disable-line
                }
            }
        });

        this.on("submit.failed", (result) => {
            const $state = this.$form.querySelector(".form__state");
            $state.innerHTML = "";

            const $message = document.createElement("div");
            $message.classList.add("form__message", "failed");

            this.$form.classList.add("failed");

            $message.textContent = this.getMessage(result.code);
            $state.appendChild($message);
            this.enableSubmit();
        });
    }

    /**
     * Overwrite default event handlers
     * @param {Node} $field
     * @return {bool}
     */
    on(name, callback) {
        if (typeof callback !== "function") {
            throw new FormError(`Invalid callback for "${name}" event @ #${this.$form.id}`);
        }
        if (!Object.hasOwnProperty.call(this.events, name)) {
            throw new FormError(`Could not add event "${name}" @ #${this.$form.id}`);
        }
        this.events[name] = callback;
    }

    /**
     * Add a custom event listener
     * @param {int} Score for password
     * @return {}
     */
    metter(score) {
        if (this.$metter) {
            const metterBar = this.$metter.querySelector("#metterBar");
            metterBar.setAttribute("data-strength", score);
        }
    }

    setupFile(key, value) {
        if (typeof this.data.fields[key] === "undefined") {
            this.data.fields[key] = [];
        }

        this.data.fields[key].push(value);
    }

    /**
     * Test a specific field for validation
     * @param {Node} $field
     * @return {bool}
     */
    validate($field) {
        const $parent = $field.parentElement;
        const $message = $parent.nextElementSibling;

        const { success, message, name, value } = this.testField($field); // eslint-disable-line

        // registers new value
        if (name === "") {
            if ($field.type !== "search") {
                if (this.options.debug) {
                    console.warn(`Field "${$field.id}" has no name`); // eslint-disable-line
                }
            }
        } else if ($field.dataset.ignore !== "1") {
            this.data.fields[name] = value;
        }

        // show changes
        if (!success) {
            if ($message) {
                $message.innerHTML = message;
            }

            if ($field.type !== "hidden") {
                $parent.classList.remove("valid");
                $parent.classList.add("invalid");
            }
        } else {
            if ($field.type !== "hidden" && $message) {
                $message.innerHTML = "";
            }

            if ($field.type !== "hidden") {
                $parent.classList.add("valid");
                $parent.classList.remove("invalid");
            }
        }

        return success;
    }

    cleanFilepond() {
        this.$form.querySelector(".filepond--root").nextElementSibling.innerHTML = "";
        this.$fields.forEach((input, index) => {
            if (input.classList.contains("filepond--browser")) {
                this.$fields.splice(index, 1);
            }
        });
        this.fileHasError = false;
    }

    /**
     * Test all fields for validation and returns true if everything is ok
     * @return {bool}
     */
    validateAll() {
        if (this.$form.querySelector(".filepond--root")) {
            const files = this.$form.querySelectorAll(".filepond--data input");
            if (files.length < 1) {
                if (this.$form.querySelector(".filepond--browser").hasAttribute("required")) {
                    if (!this.fileHasError) {
                        const browser = this.$form.querySelector(".filepond--browser");
                        browser.setAttribute(
                            "data-code",
                            this.$form.querySelector(".filepond--root").parentElement.dataset.code
                        );
                        this.$fields.push(browser);
                        this.fileHasError = true;
                    }
                }
            } else {
                this.cleanFilepond();
            }
        }
        return this.$fields.reduce((bool, $field) => {
            const validated = this.validate($field);
            return bool && validated;
        }, true);
    }

    /**
     * Validates a field and returns validation data
     * @param {Node} $field
     * @return {Object}
     */
    testField($field) {
        const value = validator.escape($field.value);
        const name = $field.name; // eslint-disable-line
        const code = $field.dataset.code; // eslint-disable-line

        if ($field.type === "hidden" && value === "") {
            throw new FormError(`Empty hidden field "${$field.name}" @ #${this.$form.id}`);
        }

        switch ($field.type) {
            case "file":
                return this.feedback(
                    code,
                    name,
                    value,
                    $field.required,
                    value === "",
                    false, // access only here if there's no file so always return false
                    "FOR"
                );
            case "radio":
                return this.feedback(
                    code,
                    name,
                    document.querySelector(`input[name="${name}"]:checked`) !== null
                        ? document.querySelector(`input[name="${name}"]:checked`).value
                        : "",
                    $field.required,
                    document.querySelector(`input[name="${name}"]:checked`) === null
                );
            case "checkbox":
                return this.feedback(
                    code,
                    name,
                    document.querySelector(`input[name="${name}"]:checked`) !== null ? 1 : 0,
                    $field.required,
                    !$field.checked
                );

            default:
                switch (code) {
                    case "EMA":
                        return this.feedback(
                            code,
                            name,
                            validator.normalizeEmail(value),
                            $field.required,
                            value === "",
                            validator.isEmail(validator.normalizeEmail(value)),
                            "FOR"
                        );

                    // TODO: check phone for instance 0388312299 doesn't work yet
                    case "PHO":
                        return this.feedback(
                            code,
                            name,
                            value,
                            $field.required,
                            value === "",
                            validator.isMobilePhone(value, this.locale, this.options.strictMode) ||
                                this.validateFrenchPhone(value),
                            "FOR"
                        );

                    case "PAS": // eslint-disable-line
                        if (value.length < 6) {
                            this.metter(0);
                            return this.feedback(
                                code,
                                name,
                                value,
                                $field.required,
                                value === "",
                                false,
                                "FOR",
                                "Minimum 6 caractères"
                            );
                        }
                        const result = zxcvbn(value, passwordMessages[this.locale]); // eslint-disable-line
                        const message = `${result.feedback.suggestions.join(" | ")} <br /> ${
                            result.feedback.warning
                        }`; // eslint-disable-line
                        this.metter(result.score);
                        return this.feedback(
                            code,
                            name,
                            value,
                            $field.required,
                            value === "",
                            result.score >= this.pwdMin,
                            "FOR",
                            message
                        );

                    case "CPA": // eslint-disable-line
                        const $passwordField = this.$form.querySelector('input[name="password"]'); // eslint-disable-line
                        if (!$passwordField) {
                            throw new FormError(`Password field not found @ #${this.$form.id}`);
                        }
                        return this.feedback(
                            code,
                            name,
                            value,
                            $field.required,
                            value === "",
                            $passwordField.value === $field.value,
                            "DIF"
                        );

                    case "ZIP":
                        return this.feedback(
                            code,
                            name,
                            value,
                            $field.required,
                            value === "",
                            validator.isPostalCode(value, this.locale.substring(this.locale.length - 2)),
                            "FOR"
                        );

                    default:
                        if ($field.classList.contains("select__store_field")) {
                            return this.feedback(
                                code,
                                name,
                                this.getSelectValues($field),
                                $field.required,
                                value === "0",
                                !$field.pattern || $field.value.match($field.pattern),
                                "FOR"
                            );
                        }
                        return this.feedback(
                            code,
                            name,
                            value,
                            $field.required,
                            value === "",
                            !$field.pattern || $field.value.match($field.pattern),
                            "FOR"
                        );
                }
        }
    }

    /**
     * Triggers form submit
     * @return {void}
     */
    submit() {
        if (this.events[Form.EVENT_SUBMIT]) {
            this.events[Form.EVENT_SUBMIT]();
        }

        if (!this.validateAll()) {
            if (this.events[Form.EVENT_SUBMIT_FAILED]) {
                this.events[Form.EVENT_SUBMIT_FAILED]({ code: "F0VALCLI" });
            }
            this.enableSubmit();
            return;
        }

        if (this.fileponds) {
            const filesElements = this.$form.querySelectorAll('.filepond--data input[type="hidden"]');
            if ((!this.fileponds.required && filesElements.length > 0) || this.fileponds.required) {
                this.fileponds.onprocessfiles = () => {
                    this.becauseMagic();
                };

                const msg = this.getMessage("F2FILPRO");
                const $state = this.$form.querySelector(".form__state");
                $state.innerHTML = "";

                const $message = document.createElement("div");
                $message.classList.add("form__message", "complete");
                $message.textContent = msg;
                $state.appendChild($message);

                this.$form.classList.remove("failed");
                this.$form.classList.remove("complete");
                this.$form.classList.add("processing");
                this.fileponds.processFiles();
            } else {
                this.data.fields[this.fileponds.name] = "";
                this.applySubmit();
            }
        } else {
            this.applySubmit();
        }
    }

    // because of processFiles().then executing before the promise itself
    becauseMagic() {
        const filesElements = this.$form.querySelectorAll('.filepond--data input[type="hidden"]');
        if (filesElements.length > 0) {
            const filesData = {};
            Array.from(filesElements).forEach((el) => {
                const tmp = el.getAttribute("value");
                if (typeof filesData[el.name] !== "object") {
                    filesData[el.name] = [];
                }
                filesData[el.name].push(tmp);
            });
            this.data.fields = { ...this.data.fields, ...filesData };
        }
        this.applySubmit();
    }

    getSelectValues(select) {
        // eslint-disable-line
        let result = "";
        const options = select && select.options;
        let opt;

        for (let i = 0, iLen = options.length; i < iLen; i++) {
            // eslint-disable-line
            opt = options[i];

            if (opt.selected) {
                result += `${opt.value}, `;
            }
        }
        return result.substring(0, result.length - 2);
    }

    applySubmit() {
        // async form submit
        if (this.options.async === true) {
            const target = `${GLOBALS.FORM_ACTION}?action=${this.action}`; // eslint-disable-line
            const that = this;
            /* eslint-disable */
            grecaptcha.ready(function () {
                grecaptcha.execute(GLOBALS.RECAPTCHA_SITE_KEY, { action: "submit" }).then(function (token) {
                    that.data.fields.recaptcha_response = token;
                    httpA
                        .post("", target, qs.stringify(that.data))
                        .then(({ ...response }) => {
                            if (response.status === 200) {
                                if (that.events[Form.EVENT_SUBMIT_COMPLETED]) {
                                    that.events[Form.EVENT_SUBMIT_COMPLETED](response);
                                }
                            } else if (that.events[Form.EVENT_SUBMIT_FAILED]) {
                                that.events[Form.EVENT_SUBMIT_FAILED](response);
                            }
                        })
                        .catch((e) => {
                            console.error(e); // eslint-disable-line
                        });
                });
            });
            /* eslint-enable */
        } else {
            // normal submit
            this.$form.submit();
        }
    }

    /**
     * Applies to all form fields a given key / value
     * @param {string} attribute - HTML attribute
     * @param {any} value
     */
    // eslint-disable-next-line
    applyToAll(attribute, value, applyToSubmit = true) {
        if (applyToSubmit && this.$submit) {
            this.$submit[attribute] = value;
        }
        this.$fields.forEach(($e) => {
            const $field = $e;
            if ($field) {
                $field[attribute] = value;
            }
        });
    }

    /**
     * Disable form
     */
    disable() {
        this.$form.classList.add("disabled");
        // eslint-disable-next-line
        this.applyToAll("disabled", true, false); // eslint-disable-line
    }

    /**
     * Enable form
     */
    enable() {
        this.$form.classList.remove("disabled");
        // eslint-disable-next-line
        this.applyToAll("disabled", false, false); // eslint-disable-line
    }

    /**
     * Disable submit button
     */
    disableSubmit() {
        this.$submit.classList.add("disabled");
        this.$submit.setAttribute("disabled", "true"); // eslint-disable-line
    }

    /**
     * Enable submit button
     */
    enableSubmit() {
        this.$submit.classList.remove("disabled");
        this.$submit.removeAttribute("disabled"); // eslint-disable-line
    }

    /**
     * Get the message associated with a given code
     * @param {string} code
     * @param {string} name
     */
    getMessage(code, name = null) {
        const list = this.CUSTOM_MESSAGES;

        if (list[name] && list[name][code]) {
            return list[name][code];
        }

        if (!this.MESSAGES[code] && this.options.debug) {
            console.warn(`Tried to load message "${code}" but failed`); // eslint-disable-line
        }

        return this.MESSAGES[code] || null;
    }

    /**
     * Returns a feedback object
     * @param {string} Field identifier e.g TEX
     * @param {string} name - Name property
     * @param {any} value - Cleaned up value
     * @param {boolean} required - Required attribute value
     * @param {boolean} empty - Value is considered empty
     * @param {boolean} valid - Result of the validation test
     * @param {string|array} suffix - Code suffix e.g FOR or REQ
     * @param {string} customMess - Bypass validation message w/ a custom message
     * @return {Object}
     */
    feedback(
        field,
        name,
        value,
        required = false,
        empty = false,
        valid = true,
        suffix = "",
        customMess = null
    ) {
        const codes = [];

        // generate error codes
        if (required && empty) {
            codes.push(`F0${field}REQ`);
        }
        if (!valid && !empty) {
            if (Array.isArray(suffix)) {
                suffix.forEach((s) => codes.push(`F0${field}${s}`));
            } else {
                codes.push(`F0${field}${suffix}`);
            }
        }

        // retrieve error code messages
        const messages = codes.map((c) => this.getMessage(c, name));

        /*
         * TODO:
         * Custom messages allows zxcvbn validation to provide error messages
         * directly but maybe it should only return an error code so that
         * passwordMessages.json can be merged with the main message system
         */
        if (customMess) {
            messages.push(customMess);
        }

        return {
            success: codes.length === 0, // success if no error codes are generated
            message: messages.join(" | "),
            name,
            value,
        };
    }

    validateFrenchPhone(number) {
        // eslint-disable-line
        const regex = new RegExp(/^(01|02|03|04|05|06|08)[0-9]{8}/gi);

        // Definition de la variable booleene match
        let match = false;

        // Test sur le motif
        if (regex.test(number)) {
            match = true;
        } else {
            match = false;
        }

        // On renvoie match
        return match;
    }
}

Form.EVENT_SUBMIT = "submit";
Form.EVENT_SUBMIT_COMPLETED = "submit.completed";
Form.EVENT_SUBMIT_FAILED = "submit.failed";

Form.DEFAULT_CFG = {
    async: true,
    strictMode: false,
    debug: false,
};

export { FormError };

export default Form;
