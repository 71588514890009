import md5 from 'js-md5';

class Callback {
    constructor() {
        this.api = 'massapi';
        this.accountId = 5892;
        this.host = 'api.wannaspeak.com';
        this.baseUrl = 'https://api.wannaspeak.com/api/api.php';
        this.secret = GLOBALS.CALLBACK_SECRET;
        this.lid = 33072;
        this.key = '';
        this.phone = '';
    }

    setPhone(phone) {
        this.phone = phone;
    }

    buildKey() {
        if(this.phone !== '') {
            this.key = md5(md5(this.lid + this.phone + this.accountId + this.api + this.host) + this.secret);
        }
    }

    openPopup() {
        const url = this.buildUrl();

        if(url != '') {
            window.open(url,"Monsieur Meuble vous rappelle","menubar=no,toolbar=no,width=540,height=365,resizable=yes,scrollbars=no,directories=no,location=no");
        }

    }

    addIframe(url) {
        if(url !== '') {
            document.querySelector('#modal-callback .form__container').remove();
            document.querySelector('#modal-callback .form__rgpd').remove();
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', url);
            iframe.setAttribute('class', 'callback__iframe');
            iframe.setAttribute('frameborder', '0');
            document.querySelector('#modal-callback .nav-secondary').append(iframe);
        }
    }

    buildUrl() {
        this.buildKey();
        if(this.key !== '') {
            return `${this.baseUrl}?api=${this.api}&popup=1&lid=${this.lid}&to=${this.phone}&key=${this.key}`;
        } else {
            return '';
        }
    }
}

export default Callback;
