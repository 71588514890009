import qs from 'qs';
import httpA from 'coreJs/actions/httpActions';
import Storage from './storage';

class Wishlist {
    constructor() {
        this.elements = {
            headerCounter: document.querySelector('.btn-wishlist__counter'),
        };
        this.action = 'toggle_wishlist';
        this.lock = false;
        this.storage = new Storage('tmp_wishlist', false);

        this.wishlistClose = document.querySelector('.btn-close-modal[data-target="modal-favorites"]')

        if (document.querySelectorAll('[data-product]').length > 0) {
            this.initEvents();
        }
    }

    initEvents() {
        const storedWishlist = this.storage.get('tmp_wishlist');
        if (Object.keys(storedWishlist).length !== 0 && storedWishlist.constructor === Object) {
            if (GLOBALS.USER_LOGGED_IN) {
                this.lock = true;
                const buttons = document.querySelectorAll(`[data-product="${storedWishlist.product}"]`);
                this.toggleProductFromWishlist(buttons, storedWishlist.product, false)
                .then(() => {
                    this.lock = false;
                    this.storage.remove('tmp_wishlist');
                });
            } else {
                this.storage.remove('tmp_wishlist');
                this.lock = false;
            }
        }

        if(this.wishlistClose) {
            this.wishlistClose.addEventListener('click', () => {
                this.lock = false;
                this.storage.remove('tmp_wishlist');
            });
        }

        Array.from(document.querySelectorAll('.wishlist__toggle')).forEach((button) => {
            button.addEventListener('click', () => {
                if (!this.lock) {
                    this.lock = true;
                    const productID = button.getAttribute('data-product');
                    const buttons = document.querySelectorAll(`[data-product="${productID}"]`);
                    this.toggleProductFromWishlist(buttons, productID, true)
                        .then(() => {
                            this.lock = false;
                        });
                }
            });
        });

        Array.from(document.querySelectorAll('.btn-open-modal[data-target="modal-favorites"]')).forEach((button) => {
            button.addEventListener('click', () => {
                const datas = {
                    action: this.action,
                    product: button.getAttribute('data-product'),
                };
                this.storage.set(datas);
            });
        });
    }

    async toggleProductFromWishlist(buttons, productID, initialToggle) {
        const datas = {
            action: this.action,
            product: productID,
        };

        this.buttons = buttons;
        if(initialToggle) {
            Array.from(this.buttons).forEach((btn) => {
                btn.classList.toggle('active');
            });
        }
        const that = this;
        return new Promise((resolve, reject) => {
            httpA.post('', GLOBALS.FORM_ACTION, qs.stringify(datas), { responseType: 'text' }) // eslint-disable-line
                .then((response) => {
                    if (response.data.status === 'added') {
                        Array.from(this.buttons).forEach((btn) => {
                            btn.classList.add('active');
                        });
                    } else {
                        Array.from(this.buttons).forEach((btn) => {
                            btn.classList.remove('active');
                        });
                    }

                    if(Number.isInteger(response.data.count) && response.data.count >= 0) {
                        that.elements.headerCounter.innerHTML = response.data.count;
                    }
                    resolve();
                }).catch((e) => {
                    console.error(e); // eslint-disable-line
                    reject();
                });
        });
    }
}

export default Wishlist;
