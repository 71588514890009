class Nav {
    constructor() {
        this.header = document.getElementById("header");
        this.btnBurger = document.getElementById("btn-burger");
        this.btnCallBack = document.getElementById("btn-callback");
        this.modalSecondary = document.getElementById("modal-secondary");
        this.navSecondaryMenu = document.getElementById("nav-secondary__menu");
        this.navTertiaryMenu = document.getElementById("nav-tertiary__menu");
        this.barPromo = document.getElementById("bar-promo");
        this.lastScroll = 0;
    }

    init() {
        this.initNavSecondary();
        window.addEventListener("scroll", (e) => this.toggleStickyHeader());
    }

    //        Nav secondary (burger menu)
    // ==============================================

    initNavSecondary() {
        this.btnBurger.addEventListener("click", (e) => this.openNavSecondary(e));
        this.modalSecondary
            .querySelector(".btn-close-modal-nav")
            .addEventListener("click", (e) => this.closeNavSecondary(e));
        this.modalSecondary
            .querySelector(".modal-secondary__overlay")
            .addEventListener("click", (e) => this.closeNavSecondary(e));

        this.navSecondaryMenu.querySelectorAll(".nav-secondary__link.has-submenu").forEach((el) => {
            el.addEventListener("click", (e) => this.openNavSecondarySubMenu(e));
        });
        this.navSecondaryMenu.querySelectorAll(".btn-back").forEach((el) => {
            el.addEventListener("click", (e) => this.closeNavSecondarySubMenu(e));
        });
        // document.querySelectorAll('.nav-secondary__link.has-submenu').forEach( function( el ) {
        //     el.addEventListener('click', this.openNavSecondaryMenu.bind(this));
        // }.bind(this));
    }

    openNavSecondary(e) {
        this.modalSecondary.classList.add("is-open");
        this.header.classList.add("has-nav-open");
    }

    closeNavSecondary(e) {
        this.modalSecondary.classList.remove("is-open");
        this.header.classList.remove("has-nav-open");
        this.closeNavSecondarySubMenu();
    }

    openNavSecondarySubMenu(e) {
        e.preventDefault();
        const el = e.target;
        this.navSecondaryMenu.classList.add("is-open");
        // this.navTertiaryMenu.classList.add('is-open');
        el.parentNode.querySelector(".nav-secondary__submenu-nav").classList.add("is-open");
    }

    closeNavSecondarySubMenu(e = null) {
        this.navSecondaryMenu.classList.remove("is-open");
        // this.navTertiaryMenu.classList.remove('is-open');
        document.querySelectorAll(".nav-secondary__submenu-nav").forEach((el) => {
            el.classList.remove("is-open");
        });
    }

    //        Stick header on top when scroll
    // ==============================================

    toggleStickyHeader(e) {
        const currentScroll = document.body.scrollTop || document.documentElement.scrollTop;
        let limit = 0;

        // stick after bar promo
        if (this.barPromo) {
            limit += this.barPromo.offsetHeight;
        }

        // stick / unstick header
        if (currentScroll > limit) {
            this.header.classList.add("is-sticky");
            let header_height = 0;
            header_height += document.querySelector(".bar-tools").offsetHeight;
            header_height += document.querySelector(".bar-primary").offsetHeight;
            document.body.style.setProperty("--header-height", `${header_height}px`);
        } else {
            this.header.classList.remove("is-sticky");
            document.body.style.setProperty("--header-height", "0");
        }

        // show / hide header when scroll up / down
        if (
            currentScroll > limit + 400 &&
            currentScroll > this.lastScroll &&
            !this.header.classList.contains("has-nav-open")
        ) {
            this.header.classList.add("is-close");
            // set sticky blocks position on top of window
            $(".filters-mobile-bar").removeClass("is-pushed");
            $(".sticky-store").removeClass("is-pushed");
        } else {
            this.header.classList.remove("is-close");
            // push sticky blocks position under header
            $(".filters-mobile-bar").addClass("is-pushed");
            $(".sticky-store").addClass("is-pushed");
        }
        this.lastScroll = currentScroll;
    }
}

export default Nav;
